var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"#cf9602"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"text-h3 font-weight-light"},[_vm._v(" Active/Pickup/Pending Orders ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.users,"item-key":"_id","hide-default-footer":"","disable-pagination":"","loading":_vm.loading,"loading-text":"Loading... Please wait","item-class":_vm.itemStatusColor},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.numbers[index])+" ")]}},{key:"item.store_name",fn:function(ref){
var item = ref.item;
return [(item.store_name[0] != null)?_c('div',[_vm._v(" "+_vm._s(item.store_name[0].name)+" ")]):_vm._e()]}},{key:"item.role_name",fn:function(ref){
var item = ref.item;
return [(item.role_name[0] != null)?_c('div',[_vm._v(" "+_vm._s(item.role_name[0].name)+" ")]):_vm._e()]}},{key:"item.user_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_type[0].name)+" ")]}},{key:"item.order_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.order_date))+" ")]}}],null,true)}),_c('div',{staticClass:"custom_pagination_design"},[_c('span',[_vm._v(" Showing "),_c('b',[_vm._v(_vm._s(_vm.startRecord))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(_vm.endRecord))]),_vm._v(" of "),_c('b',[_vm._v(_vm._s(_vm.totalRecord))]),_vm._v(" Records ")]),_c('v-pagination',{staticClass:"float-right",attrs:{"length":_vm.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }