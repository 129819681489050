<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-col cols="12" md="12">
      <base-material-card color="#cf9602" class="px-5 py-3">
        <template v-slot:heading>
          <div class="text-h3 font-weight-light">
            Active/Pickup/Pending Orders
          </div>
        </template>
        <v-card-text>
          <v-data-table
            :headers="columns"
            :items="users"
            item-key="_id"
            hide-default-footer
            disable-pagination
            :loading="loading"
            loading-text="Loading... Please wait"
            :item-class="itemStatusColor"
          >
            <template v-slot:[`item._id`]="{ index }">
              {{ numbers[index] }}
            </template>
            <template v-slot:[`item.store_name`]="{ item }">
              <div v-if="item.store_name[0] != null">
                {{ item.store_name[0].name }}
              </div>
            </template>
            <template v-slot:[`item.role_name`]="{ item }">
              <div v-if="item.role_name[0] != null">
                {{ item.role_name[0].name }}
              </div>
            </template>
            <template v-slot:[`item.user_type`]="{ item }">
              {{ item.user_type[0].name }}
            </template>
            <template v-slot:[`item.order_date`]="{ item }">
              {{ format_date(item.order_date) }}
            </template>
          </v-data-table>
          <div class="custom_pagination_design">
            <span>
              Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
              <b>{{ totalRecord }}</b> Records
            </span>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </div>
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "ActivePendingOrders",
  props: [],
  data() {
    return {
      orderStatus: ["Accepted", "Pending", "Pickup"],
      users: [],
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
    };
  },
  computed: {
    columns() {
      return [
        { sortable: false, text: "Order Id", value: "id", width: "150px" },
        {
          text: "FirstName",
          value: "user_name[0].first_name",
          width: "150px",
        },
        {
          text: "LastName",
          value: "user_name[0].last_name",
          width: "150px",
        },
        {
          sortable: false,
          text: "Email",
          value: "user_name[0].email",
          width: "150px",
        },
        {
          sortable: false,
          text: "Order Status",
          value: "order_status",
          width: "150px",
        },
        {
          sortable: false,
          text: "Payment Status",
          value: "payment_status",
          width: "180px",
        },
        {
          sortable: false,
          text: "Order Date",
          value: "order_date",
          width: "150px",
        },
        {
          sortable: false,
          text: "Grand Total",
          value: "grant_total",
          width: "150px",
        },
        {
          sortable: false,
          text: "Delivery Charges",
          value: "delivery_charges",
          width: "180px",
        },
      ];
    },
  },
  methods: {
    getUsers() {
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "ordersByStatus" +
            "?page=" +
            this.page,
          {
            driverId: null,
            customerId: null,
            orderStatus: this.orderStatus,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.users = response.data.orders.data;
            this.loading = false;
            this.totalPages = response.data.orders.last_page;
            this.startRecord = response.data.orders.from;
            this.endRecord = response.data.orders.to;
            this.totalRecord = response.data.orders.total;
            this.numbers = [];
            for (
              let num = response.data.orders.from;
              num <= response.data.orders.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getUsers();
    },
    itemStatusColor: function (item) {
      if (item.order_status == "Pending") {
        return "pending";
      } else if (item.order_status == "Accepted") {
        return "active";
      } else if (item.order_status == "PickUp") {
        return "pickup";
      } else {
        return;
      }
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY h:m:s");
      }
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style>
.pending {
  background-color: #ff000021;
}
.active {
  background-color: #fffe0026;
}
.pickup {
  background-color: #fffe0026;
}
.completed {
  background-color: #00800026;
}
</style>
